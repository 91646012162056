import React from 'react'
import TopNavbar from '../../Components/TopNavbar/TopNavbar'
import Footer from '../../Components/Footer/Footer'
import trustDeed from "../../Components/TopNavbar/GURUKULAM EDUCATION TRUST Deed.pdf";
import buildingCertificate from "../../Components/TopNavbar/Building Certificate.pdf";
import healthCertificate from "../../Components/TopNavbar/Health and Sanitary Certificate.jpeg";
import academicCalender from "../../Components/TopNavbar/ACADEMIC PLANNER.pdf";
import parentTeacherAssociation from "../../Components/TopNavbar/Parent Teacher Association.jpeg";
import feesStructure from "../../Components/TopNavbar/Fees.pdf";
import schoolManagementCommittee from "../../Components/TopNavbar/School management committee.jpeg";
import inProcess from "../../Components/TopNavbar/Mandatory Disclosure Details _ SARAS 5.0.pdf";


const Disclosure = () => {
    return (
        <div>
            <TopNavbar />
            <DocumentTable />
            <Footer />


        </div>
    )
}

export default Disclosure
const DocumentTable = () => {
    const documents = [
        { id: 1, name: "Trust Deed", link: trustDeed },
        { id: 2, name: "RTE (School Registration)", link: inProcess },
        { id: 3, name: "Building Certificate", link: buildingCertificate },
        { id: 4, name: "No Objection Certificate", link: inProcess },
        { id: 5, name: "Health & Sanitary Certificate", link: healthCertificate },
        { id: 6, name: "Fire & Safety Certificate", link: inProcess },
        { id: 7, name: "Academic Calendar", link: academicCalender },
        { id: 8, name: "Parent-Teacher Association", link: parentTeacherAssociation },
        { id: 9, name: "School Management Committee", link: schoolManagementCommittee },
        { id: 10, name: "Fees Structure", link: feesStructure },
        { id: 11, name: "SARAS", link: inProcess },
    ];

    const tableStyle = {
        margin: "50px auto", // Centers the table
        borderCollapse: "collapse", // Ensures borders don't overlap
        width: "80%", // Makes the table take up 80% of the page width
    };

    const thTdStyle = {
        border: "1px solid black", // Thin border
        padding: "10px", // Padding inside the cells
        textAlign: "center", // Center the text in cells
    };

    return (
        <table style={tableStyle}>
            <thead>
                <tr>
                    <th style={thTdStyle}>SR NO.</th>
                    <th style={thTdStyle}>DOCUMENTS</th>
                    <th style={thTdStyle}>DETAILS</th>
                </tr>
            </thead>
            <tbody>
                {documents.map((doc) => (
                    <tr key={doc.id}>
                        <td style={thTdStyle}>{doc.id}</td>
                        <td style={thTdStyle}>{doc.name}</td>
                        <td style={thTdStyle}>
                            <a href={doc.link} target="_blank" rel="noopener noreferrer">
                                Click Here
                            </a>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
