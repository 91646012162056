import './App.css';
import Disclosure from './Pages/Landing page/Disclosure';
import LandingPage from './Pages/Landing page/LandingPage';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/disclosure" element={<Disclosure />} />
    </Routes>

  );
}

export default App;
